// src/redux/store.js
import { configureStore } from '@reduxjs/toolkit';
import leadsReducer from './leadsSlice';
import reportReducer from './reportSlice';

const store = configureStore({
  reducer: {
    leads: leadsReducer,
    report: reportReducer
  },
});

export default store;
