import React from "react";

const PlanCards = ({
  plan,
  onClick,
  onSelect,
  formatDate,
  isSelected,
  variant = "default",
}) => {
  
  if (variant === "selector") {
    return (
      
      <div
        className={`bg-white p-4  rounded-lg  cursor-pointer transition-all ${
          isSelected
            ? "border-2 border-blue-500 bg-blue-50"
            : "border border-gray-200 hover:border-blue-300"
        }`}
        onClick={() => onSelect(plan.id)}
      >
        <div className="flex justify-between items-start">
          <h3 className="text-lg font-semibold text-black  flex-1">
            {plan.title}
          </h3>
          <span
            className={`px-3 py-1 text-sm rounded-full ${
              isSelected
                ? "bg-blue-500 text-white"
                : "bg-gray-100 text-gray-600"
            }`}
          >
            {isSelected ? "Selected" : "Select Plan"}
          </span>
        </div>
      </div>
    );
  }

  
  return (
    <div
      className={`bg-white p-4 flex flex-col gap-y-2 rounded-lg shadow-md cursor-pointer ${
        isSelected ? "bg-blue-200 border-blue-400" : "bg-white border-gray-200"
      }`}
      onClick={() => onClick(plan)}
    >
      <h3 className="text-xl text-black font-semibold">{plan.title}</h3>
      <div className="flex justify-between items-center text-sm">
        <p className="text-gray-600">Created By {plan.created_by}</p>
        <p className="text-gray-500">{formatDate(plan.created_at)}</p>
      </div>
    </div>
  );
};

export default PlanCards;
