import React from 'react'
import PlanCards from '../components/PlanCards';
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

function PlanSelection({ plans, selectedPlan, onPlanSelect, isLoading }) {
  console.log("heloo", plans);

  return (
    <div>
      {plans.length > 0 ? (
        <div className="overflow-y-auto scrollbar-hide max-h-[calc(100vh-150px)]">
          <div className="flex flex-col gap-y-3">
            {plans.map((plan) => (
              <PlanCards
                key={plan.id}
                plan={plan}
                isSelected={selectedPlan === plan.id}
                onSelect={onPlanSelect}
                variant="selector"
              />
            ))}
          </div>
        </div>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="200px"
        >
          <Typography variant="body1    " color="black" fontWeight="bold">
            Plans not available for this country ...Create New Plans Now!!!
          </Typography>
        </Box>
      )}
    </div>
  );
}

export default PlanSelection