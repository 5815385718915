import axiosInstance from "./axiosinstance";
import { planByAppliedCourse, planExecution, getPlanStatus } from "./api";


export const planByCourseFunction = async (cousreId) => {
    try {
        
        const response = await axiosInstance.get(planByAppliedCourse(cousreId))
        return response.data.responsedata;
    } catch (error) {
        console.error("Error fetching plans for this course:", error);
        return [];
    }
}

export const planExecutionFunction = async (applyCourse, stepBranch, status) => {
    try {

        const response = await axiosInstance.put(planExecution, {
            apply_course: applyCourse,
            step_branch: stepBranch,
            status: status,
        })
        return response.data;
    } catch (error) {
        console.error("Error Verifying the step", error);
        throw error;
    }
}

export const getPlanStatusFunction = async (applyCourse, stepBranch) => {
    try {
        const response = await axiosInstance.post(getPlanStatus, {
            apply_course: applyCourse,
            step_branch: stepBranch,
        })
        return response.data.responsedata
    } catch (error) {
        console.error("Error fetching the  step status", error);
        throw error;
    }


}