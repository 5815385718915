import React, { useEffect, useState } from 'react'

import Header from "../../../components/header";
import ConversationComponent from '../components/ConversationComponent';
import PlanSteps from '../components/PlanSteps';
import StudentProfileComponent from '../components/StudentProfileComponent';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { fetchCourseDetailsById } from '../services/AppliedCourseService';
import { fetchPlansByCountry } from '../services/CountriesService';
import ProcessingTimeline from '../components/ProcessingTimeline';
import { planByCourseFunction } from "../services/PlansExecutionService";


// import { FaArrowLeft } from "react-icons/fa";

function PlanStatusPage() {
  const { cousreId, studentuserId } = useParams();
  console.log("kl", cousreId, studentuserId);

  const [plansForTimeline, setPlansForTimeline] = useState([]);
  const [courseDetails, setCourseDetails] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [planByCourse, setPlanByCourse] = useState(null);
  const [planExist, setPlanExist] = useState(false);
  const [allocatedPlanId, setAllocatedPlanId] = useState(0);
   const [completionPercentage, setCompletionPercentage] = useState(0);

  const calculateCompletionPercentage = (plan) => {
    if (!plan?.steps) return 0;

    let totalSteps = plan.steps.length; 
    let completedSteps = 0; 
    let totalDocuments = 0;
    let approvedDocuments = 0;

    plan.steps.forEach((step) => {
      if (!step.branches || step.branches.length === 0) {
        completedSteps += 1;
      } else {
        let stepHasDocuments = false;
        let allDocumentsApproved = true;

        step.branches.forEach((branch) => {
          if (branch.is_upload) {
            stepHasDocuments = true;
            totalDocuments += 1;

            if (branch.status === "Approved") {
              approvedDocuments += 1;
            } else {
              allDocumentsApproved = false;
            }
          }
        });

        
        if (!stepHasDocuments) {
          completedSteps += 1;
        }
        else if (allDocumentsApproved) {
          completedSteps += 1;
        }
      }
    });

    
    if (totalDocuments === 0) {
      return Math.round((completedSteps / totalSteps) * 100);
    } else {
      const stepsPercentage = (completedSteps / totalSteps) * 100;
      const documentsPercentage = (approvedDocuments / totalDocuments) * 100;
      return Math.round((stepsPercentage + documentsPercentage) / 2);
    }
  };

   const handleDocumentVerification = async (
     stepIndex,
     branchIndex,
     newStatus
   ) => {
     if (!planByCourse) return;
     
     const updatedPlan = JSON.parse(JSON.stringify(planByCourse));

     updatedPlan.steps[stepIndex].branches[branchIndex].status = newStatus;

     setPlanByCourse(updatedPlan);

     const newPercentage = calculateCompletionPercentage(updatedPlan);
     setCompletionPercentage(newPercentage);
   };
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchCourseDetailsById(cousreId);
        setCourseDetails(data);
        if (data.plan_id !== "0") {
          setAllocatedPlanId(data.plan_id);
          // setPlanExist(true)
        }
        const plansData = await fetchPlansByCountry(data.country);
        setPlansForTimeline(plansData);
        const plansCourse = await planByCourseFunction(cousreId);
        setPlanByCourse(plansCourse);

        if (plansCourse && data.plan_id !== "0") {
          const percentage = calculateCompletionPercentage(plansCourse);
          setCompletionPercentage(percentage);
        } else {
          setCompletionPercentage(0);
        }
      } catch (error) {
        console.error("Error fetching course details:", error);
      }
    };

    fetchData();
  }, [cousreId]);

   useEffect(() => {
     if (planByCourse && allocatedPlanId !== 0) {
       const percentage = calculateCompletionPercentage(planByCourse);
       setCompletionPercentage(percentage);
     } else {
       setCompletionPercentage(0);
     }
   }, [planByCourse, allocatedPlanId]);
  
  // console.log('plans', plansForTimeline);
  console.log('plans',planByCourse );
  // console.log("details", courseDetails);
  // console.log("jkl", allocatedPlanId);
  const handlePlanSelect = (planId) => {
    const selected = plansForTimeline.find((plan) => plan.id === planId);
    setSelectedPlan(selected);
  };
  
  const getCompletionMessage = (percentage) => {
     if (allocatedPlanId === 0) return "Allocate plan to start process";
     if (completionPercentage === 100) return "All required documents verified";
     if (completionPercentage === 0) return "Waiting for document verification";
     return `${100 - completionPercentage}% documents pending verification`;
  };

  return (
    <>
      <div className="h-full bg-gray-200">
        {/* <div> */}
        <Header
          title={"Applied Courses"}
          subTitle={"View and manage all Applied Courses data here"}
        />
        {/* </div> */}

        <div className="flex flex-row gap-x-4 h-[88.5%] mx-6">
          <div className="w-[29%]">
            <StudentProfileComponent
              profileDetails={courseDetails}
              plans={plansForTimeline}
              onPlanSelect={handlePlanSelect}
              allocatedPlanId={allocatedPlanId}
              planByCourse={planByCourse}
            />
          </div>
          <div className="w-[37%]  h-full">
            <div className="bg-white rounded-xl px-0 py-4 text-black h-[97%]">
              <div className="flex flex-col ">
                <h3 className="font-semibold text-lg  px-6">
                  Process Timeline
                </h3>
                <p className="text-xs text-gray-500  px-6 pb-4">
                  Track your application progress
                </p>
              </div>
              <div className="overflow-y-auto h-[90%] scrollbar-hide">
                <div className="">
                  {allocatedPlanId !== 0 && (
                    <ProcessingTimeline
                      plan={planByCourse}
                      courseId={cousreId}
                      onDocumentVerification={handleDocumentVerification}
                    />
                  )}

                  {allocatedPlanId === 0 && !selectedPlan ? (
                    <div className="flex items-center justify-center ">
                      No Plans Allocated yet..Allocate Now
                    </div>
                  ) : (
                    selectedPlan && <PlanSteps plan={selectedPlan} />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="w-[34%] flex flex-col gap-y-4 h-full">
            {/* Chat Heading */}
            <div className="flex items-center bg-white gap-x-4 p-4 h-[10%] pl-6 rounded-xl ">
              <h2 className="text-3xl font-normal  text-black">
                {completionPercentage}%
              </h2>
              <div className="flex flex-col">
                <h2 className="text-lg font-semibold text-black">
                  Process Completed
                </h2>
                <p className="text-xs text-gray-500">
                  {getCompletionMessage(completionPercentage)}
                </p>
              </div>
            </div>
            {/* Chat Container */}
            <div className="h-[85%] overflow-hidden">
              <ConversationComponent studentId={studentuserId} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PlanStatusPage