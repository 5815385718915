import React, { useEffect, useState } from 'react'
import image from "../../plans/assests/images/Ai Profile image.png"
import profile_Bg from "../../plans/assests/images/Mavex background image_profile.png"
import { useParams } from 'react-router-dom';
import { allocatePlanToAppliedCourse } from '../services/AppliedCourseService';
import toast, { Toaster } from 'react-hot-toast';
import { useMutation, useQuery } from "react-query";
import DocumentDrawer from './DocumentDrawer';
import PlanSelection from '../InnerPages/PlanSelection';
// import CountrySelectionInnerPage from './InnerPages/CountrySelectionInnerPage';
import { getAllCountries, getPlansByCountryID } from '../services/CountriesService';

function StudentProfileComponent({
  profileDetails,
  plans,
  onPlanSelect,
  allocatedPlanId,
  planByCourse,
}) {
  const { cousreId } = useParams();

  // console.log("cour id", cousreId);

  console.log("qwt", planByCourse);
  const [selectedPlan, setSelectedPlan] = useState("");
  const [drawerState, setDrawerState] = useState(false);
  // const [drawerContent, setDrawerContent] = useState("");
  // const [selectedCountryInfo, setSelectedCountryInfo] = useState(null);
  // const [countryPlans, setCountryPlans] = useState([]);

  const handlePlanSelect = (planId) => {
    setSelectedPlan(planId);
    onPlanSelect?.(planId);
  };


// const {
//   data: countries,
//   isLoading: isLoadingCountries,
//   isError: isErrorCountries,
//   error: countriesError,
// } = useQuery("countries", getAllCountries, {
//   staleTime: 5 * 60 * 1000, // Consider data fresh for 5 minutes
//   cacheTime: 30 * 60 * 1000, // Keep data in cache for 30 minutes
// });
//  const {
//    data: plansByCountry,
//    isLoading: isLoadingPlans,
//    refetch: refetchPlans,
//  } = useQuery(
//    ["plans", selectedCountryInfo?.id],
//    () => getPlansByCountryID(selectedCountryInfo?.id),
//    {
//      enabled: !!selectedCountryInfo?.id,
//      onSuccess: (data) => {
//        setCountryPlans(data || []);
//      },
//    }
//     );
//   console.log("q",countryPlans);
  
//     const handleCountrySelect = async (event) => {
//       try {
//         const countryData = JSON.parse(event.target.value);
//         setSelectedCountryInfo(countryData);
//         // Reset selected plan when country changes
//         setSelectedPlan("");
//         // Fetch plans for the selected country
//         await refetchPlans();
//       } catch (error) {
//         console.error("Error parsing country data:", error);
//         toast.error("Error selecting country");
//       }
//     };
//   useEffect(() => {
//     if (selectedCountryInfo && !isLoadingPlans && countryPlans) {
//       setDrawerContent("plan");
//     }
//   }, [selectedCountryInfo, isLoadingPlans, countryPlans]);
//     const handleDrawerClick = () => {
//       if (!profileDetails?.country && !selectedCountryInfo) {
//         setDrawerContent("country");
//       } else {
//         setDrawerContent("plan");
//       }
//       setDrawerState(true);
//     };

  // const handlePlanChange = (event) => {
  //   const planId = event.target.value;
  //   setSelectedPlan(planId);
  //   onPlanSelect(planId);
  //   console.log("selected plan id", planId);
  // };
  const mutation = useMutation(
    ({ cousreId, planId }) =>
      allocatePlanToAppliedCourse(Number(cousreId), planId),
    {
      onSuccess: (data) => {
        if (data.responsecode === "200" && data.responsedata === true) {
          toast.success(`${data.responsedescription}`);
          console.log("Plan allocated successfully!", data);
        } else {
          toast.error("Failed to allocate the plan");
          console.log("Failed to allocate the plan");
        }
      },
      onError: (error) => {
        console.error("Error while submitting:", error);
        toast.error("An error occurred while submitting");
      },
    }
  );
  const handleSubmit = () => {
    if (!selectedPlan) {
      toast.error("Please select a plan before submitting");
      return;
    }

    // Trigger the mutation
    mutation.mutate({ cousreId: Number(cousreId), planId: selectedPlan });
  };
  // const handleSubmit = async () => {
  //   if (!selectedPlan) {
  //     toast.error("Please select a plan before submitting");
  //     return;
  //   }

  //   try {
  //     const success = await allocatePlanToAppliedCourse(
  //       cousreId,
  //       selectedPlan
  //     );

  //     if (success.responsecode === 200 && success.responsedata==="true") {
  //       toast.success("Plan allocated successfully!");
  //       console.log("Plan allocated successfully!",);
  //     } else {
  //       toast.error("Failed to allocate the plan");
  //     }
  //   } catch (error) {
  //     console.error("Error while submitting:", error);
  //     toast.error("An error occurred while submitting");
  //   }
  // };
  if (!profileDetails) {
    return (
      <div className="flex justify-center items-center h-full">
        <p>Loading profile...</p>
      </div>
    );
  }
  // console.log("selected plan", selectedPlan);

   const selectedPlanDetails =plans.find((plan) => plan.id === selectedPlan);

  return (
    <>
      <Toaster position="bottom-right" />
      <div className=" w-full">
        {/* Background Image */}
        <div className="flex w-full justify-center items-center">
          <img
            src={profile_Bg}
            alt="Background"
            className=" h-[9rem] object-cover rounded-lg"
            // style={{ zIndex: 0 }}
          />
        </div>

        <div
          className="flex flex-col bg-white gap-y-5 items-start rounded-xl py-5 px-6 w-full h-[35rem] scrollbar-hide overflow-y-auto"
          //   style={{ zIndex: 1 }}
        >
          <div className="flex items-center justify-start gap-x-3  w-full">
            <img src={image} alt="profile" className="w-12 h-12 rounded-lg" />

            <div className="flex flex-col items-center gap-0  text-black">
              <h3 className="font-bold text-xl">{profileDetails.name}</h3>
              <p className="font-semibold text-sm">{profileDetails.mobile}</p>
            </div>
          </div>

          {/* <div className="flex flex-row justify-between items-center w-full">
            <div className="flex flex-col ">
              <h5 className="text-black">aswinlal@gmail.com</h5>
              <p className="text-gray-600">Email</p>
            </div>
            <div className="flex flex-col ">
              <h5 className="text-black">Kochi,Kerala</h5>
              <p className="text-gray-600">Location</p>
            </div>
          </div> */}
          <div className="border border-gray-400 rounded-lg flex flex-col gap-y-3 py-4 px-7 w-full">
            <div>
              <h3 className="text-black font-bold text-2xl">
                {profileDetails.country || "Country not available"}
              </h3>
              <p className="text-gray-600">Country</p>
            </div>
            <div>
              <h3 className="text-black">
                {profileDetails.coursename || "Course not available"}
              </h3>
              <p className="text-gray-600">Course</p>
            </div>
            <div>
              <h3 className="text-black ">
                {profileDetails.university || "University not available"}
              </h3>
              <p className="text-gray-600">College</p>
            </div>
          </div>
          <div className="rounded-lg w-full flex flex-col gap-y-2 ">
            {/* <select
              className="w-full bg-gray-200 text-gray-700 py-2 rounded-lg"
              value={selectedPlan}
              onChange={handlePlanChange}
              disabled={allocatedPlanId !== 0}
            >
              <option>Select Plan</option>
              {plans.map((plan) => (
                <option key={plan.id} value={plan.id}>
                  {plan.title}
                </option>
              ))}
            </select> */}
            {allocatedPlanId !== 0 ? (
              <div>
                <p className="text-gray-600">Allocated Plan for this Course</p>
                <h3 className="font-semibold text-lg text-black">
                  {planByCourse?.title || "No title available"}
                </h3>
              </div>
            ) : (
              <>
                <div>
                  <p className="text-gray-600">
                    Select one plan from the chosen options and submit
                  </p>
                </div>
                <div
                  className={`border rounded-lg p-2 cursor-pointer ${
                    allocatedPlanId !== 0 ? "bg-gray-100" : "hover:bg-gray-50"
                  }`}
                  onClick={() => !allocatedPlanId && setDrawerState(true)}
                >
                  {selectedPlanDetails ? (
                    <>
                      <h3 className="font-semibold text-lg text-black">
                        {selectedPlanDetails.title}
                      </h3>
                    </>
                  ) : (
                    <p className="text-gray-500"> Click to select a plan</p>
                  )}
                </div>
                {/* Submit Button */}
                <div className="w-full flex flex-row justify-end pt-2">
                  <button
                    className={`${
                      allocatedPlanId !== 0
                        ? "bg-gray-400 cursor-not-allowed"
                        : "bg-black"
                    } text-white py-2 px-5 rounded-lg`}
                    onClick={handleSubmit}
                    disabled={allocatedPlanId !== 0}
                  >
                    Submit
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <DocumentDrawer
        open={drawerState}
        onClose={() => {
          setDrawerState(false);
        }}
        title="Select a plan"
      >
        <PlanSelection
          plans={plans}
          selectedPlan={selectedPlan}
          onPlanSelect={handlePlanSelect}
        />
      </DocumentDrawer>
    </>
  );
}

export default StudentProfileComponent