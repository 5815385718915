import axiosInstance from "./axiosinstance"
import { getMessages, getNotification } from "./api";

// export const fetchNotifications = async () => {
//     try {
//         const response = await axiosInstance.get(getNotification)
//         console.log("jkl", response.data.data);

//         return response.data.data
//     } catch (error) {
//         console.error("Error fetching Notification", error);
//         throw error;
//     }
// }


export const fetchChatMessages = async (studentId) => {
    try {
        const response = await axiosInstance.get(getMessages(studentId));
        return response.data.responsedata;
    } catch (error) {
        console.error("Error fetching chat messages:", error);
        throw error;
    }
};

export const fetchStudentMessages = async (studentId) => {
    try {
        const response = await axiosInstance.get(getMessages(studentId));

        const studentMessages = response.data.responsedata.filter(message =>
            message.sender_type === 'student'
        );
        return studentMessages;
    } catch (error) {
        console.error("Error fetching student messages:", error);
        throw error;
    }
};