import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { tokens } from "../../components/theme";
import Header from "../../components/header";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import PhoneIphoneOutlinedIcon from "@mui/icons-material/PhoneIphoneOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import {
  BloodtypeOutlined,
  BusinessCenterOutlined,
  BusinessOutlined,
  DateRange,
  DateRangeOutlined,
  EscalatorWarningOutlined,
  LanguageOutlined,
  Person2Outlined,
  WcOutlined,
  WorkOutlineOutlined,
} from "@mui/icons-material";
import { CreateEmployee, CreateUser } from "../../data/repos/userRepo";
import { useMutation } from "react-query";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

const LeadAllocation = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [formValues, setFormValues] = useState({});
  const [responseData, setResponsedata] = useState({});
  const handleFormSubmit = (values) => {
    console.log(values);
    fetchData(values);
  };
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const boxStyle = {
    flex: "1", // Each child takes up 50% of the parent
    // border: '1px solid #000',  // Add a border for better visibility
    margin: "10px",
    boxSizing: "border-box", // Include border in box size calculation
    backgroundColor: "#ffffff",
    borderRadius: "15px",
  };
  const previewBoxStyle = {
    //   border: "1px solid #000", // Add a border for better visibility
    margin: "0px", // Add margin for spacing between child boxes
    boxSizing: "border-box", // Include border in box size calculation
  };

  const mutation = useMutation(CreateEmployee, {
    onMutate: () => {},
    onSuccess: (data, variables, context) => {
      setResponsedata(data?.responsedata);
      console.log("Response", responseData);
      toast.success(data.responsedescription);
    },
    onError: (error) => {
      // Handle errors here
      console.error("Error:", error.message);
      toast.error(error.message);
    },
  });
  const fetchData = (userData) => {
    mutation.mutate(userData);
  };
  const data = [
    { title: "Card 1", content: "Content for Card 1" },
    { title: "Card 2", content: "x" },
    { title: "Card 3", content: "Content for Card 3" },
    { title: "Card 3", content: "Content for Card 3" },
    
   
    // Add more cards as needed
  ];
  return (
    <div>
      <Header
        title={"Lead Allocation"}
        subTitle={"Manage and allocate all available lead data"}
      />
      <Box
        m="0px 1px"
        display="flex"
        width="99%"
        height="83vh"
        justifyContent="space-between"
      >
        <Paper elevation="3" style={boxStyle}>
            <Box
            width="100%"
            height="100%"
            borderRadius="15px"
            style={previewBoxStyle}
          >
            <Grid2 container spacing={1}>
              {data.map((item, index) => (
                <Grid2 item key={index} xs={12} sm={6} md={4} lg={4}>
                  <Card
                    sx={{
                      m: "5px",
                      backgroundColor: "#ffffff",
                      borderRadius: "15px",
                    }}
                  >
                    <CardContent>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          textAlign: "center"
                        }}
                      >
                        
                        <Typography
                          variant="body2"
                          sx={{
                            mx: "10px",
                            width: "100%",
                            justifyContent: "center",
                           
                          }}
                          color="#060606"
                        >
                          {item.content}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            my: "10px",
                            textAlign: "start",
                            width: "100%",
                            justifyContent: "start",
                          }}
                          color="#000000"
                        >
                          New Leads
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            my: "10px",
                            textAlign: "end",
                            width: "100%",
                            justifyContent: "end",
                          }}
                          color="#000000"
                        >
                          25
                        </Typography>
                      </Box>
                     
                     
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            my: "10px",
                            textAlign: "start",
                            width: "100%",
                            justifyContent: "start",
                          }}
                          color="#000000"
                        >
                          Previous Leads
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            my: "10px",
                            textAlign: "end",
                            width: "100%",
                            justifyContent: "end",
                          }}
                          color="#000000"
                        >
                          15
                        </Typography>
                      </Box>
                      <LinearProgress
                        variant="determinate"
                        color="success"
                        value="15"
                      />
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            my: "10px",
                            textAlign: "start",
                            width: "100%",
                            justifyContent: "start",
                          }}
                          color="#000000"
                        >
                          Total Leads
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            my: "10px",
                            textAlign: "end",
                            width: "100%",
                            justifyContent: "end",
                          }}
                          color="#000000"
                        >
                          40
                        </Typography>
                      </Box>
                      <LinearProgress
                        variant="determinate"
                        color="success"
                        value="15"
                      />
                    </CardContent>
                  </Card>
                </Grid2>
              ))}
            </Grid2>
          </Box>
        </Paper>


        <Paper style={boxStyle}>
          <Box
            width="100%"
            height="100%"
            borderRadius="15px"
            style={previewBoxStyle}
          >
            <Grid2 container spacing={1}>
              {data.map((item, index) => (
                <Grid2 item key={index} xs={12} sm={6} md={4} lg={4}>
                  <Card
                    sx={{
                      m: "5px",
                      backgroundColor: "#ffffff",
                      borderRadius: "15px",
                    }}
                  >
                    <CardContent>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Avatar>M</Avatar>
                        <Typography
                          variant="body2"
                          sx={{
                            mx: "10px",
                            width: "100%",
                            justifyContent: "center",
                          }}
                          color="#060606"
                        >
                          {item.content}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            my: "10px",
                            textAlign: "start",
                            width: "100%",
                            justifyContent: "start",
                          }}
                          color="#000000"
                        >
                          New Leads
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            my: "10px",
                            textAlign: "end",
                            width: "100%",
                            justifyContent: "end",
                          }}
                          color="#000000"
                        >
                          25
                        </Typography>
                      </Box>
                      <LinearProgress
                        variant="determinate"
                        color="success"
                        value="45"
                      />
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            my: "10px",
                            textAlign: "start",
                            width: "100%",
                            justifyContent: "start",
                          }}
                          color="#000000"
                        >
                          Previous Leads
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            my: "10px",
                            textAlign: "end",
                            width: "100%",
                            justifyContent: "end",
                          }}
                          color="#000000"
                        >
                          15
                        </Typography>
                      </Box>
                      <LinearProgress
                        variant="determinate"
                        color="success"
                        value="15"
                      />
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            my: "10px",
                            textAlign: "start",
                            width: "100%",
                            justifyContent: "start",
                          }}
                          color="#000000"
                        >
                          Total Leads
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            my: "10px",
                            textAlign: "end",
                            width: "100%",
                            justifyContent: "end",
                          }}
                          color="#000000"
                        >
                          40
                        </Typography>
                      </Box>
                      <LinearProgress
                        variant="determinate"
                        color="success"
                        value="15"
                      />
                    </CardContent>
                  </Card>
                </Grid2>
              ))}
            </Grid2>
          </Box>
        </Paper>
      </Box>
      <Toaster position="bottom-right" reverseOrder={false} />
    </div>
  );
};

export default LeadAllocation;
