import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Radio,
} from "@mui/material";
import { Chip } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from "@mui/lab";
// import { RiAttachment2 } from "react-icons/ri";
import DocumentDrawer from "./DocumentDrawer";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import { useMutation } from "react-query";
import { getPlanStatusFunction } from "../services/PlansExecutionService";
import DocVerificationInnerPage from "../InnerPages/DocVerificationInnerPage";
import { CheckIcon } from "lucide-react";


function ProcessingTimeline({ plan, courseId, onDocumentVerification }) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [branchStatus, setBranchStatus] = useState({});

  const [selectedStepIndex, setSelectedStepIndex] = useState(null);
  const [selectedBranchIndex, setSelectedBranchIndex] = useState(null);

  useEffect(() => {
    if (plan?.steps) {
      const initialStatus = {};
      plan.steps.forEach((step) => {
        step.branches.forEach((branch) => {
          if (branch.status) {
            initialStatus[branch.id] = branch.status;
          }
        });
      });
      setBranchStatus(initialStatus);
    }
  }, [plan]);

  const mutation = useMutation(
    ({ courseId, branchId }) => getPlanStatusFunction(courseId, branchId),
    {
      onSuccess: (data) => {
        console.log("resp", data);
        setBranchStatus((prevState) => ({
          ...prevState,
          [data.id]: data.status,
        }));

        if (selectedStepIndex !== null && selectedBranchIndex !== null) {
          onDocumentVerification(
            selectedStepIndex,
            selectedBranchIndex,
            data.status
          );
        }
      },
      onError: (error) => {
        console.log("Error fetching stat the step.");
      },
    }
  );
  if (!plan || !plan.steps) {
    console.warn("Plan or steps are undefined for processingTimeline");
    return <div>No Plan Available</div>;
  }
  const handleOpenDrawer = (branch, stepIndex, branchIndex) => {
    setSelectedBranch(branch);
    setIsDrawerOpen(true);
     setSelectedStepIndex(stepIndex);
     setSelectedBranchIndex(branchIndex);
  };

  const handleStatusChange = (branchId, status) => {
    setBranchStatus((prevState) => ({
      ...prevState,
      [branchId]: status,
    }));
    mutation.mutate({ courseId, branchId });
    setIsDrawerOpen(false);
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
    setSelectedStepIndex(null);
    setSelectedBranchIndex(null);
    //  setSelectedBranch(null);
  };

  // console.log('status',branchStatus);
  const getStatusColor = (status) => {
    switch (status) {
      case "Approved":
        return "green";
      case "Rejected":
        return "red";
      default:
        return "gray";
    }
  };

  const getTimelineConnectorColor = (stepIndex) => {
    let allSubstepsVerified = true;
    for (const branch of plan.steps[stepIndex].branches) {
      if (
        !branch.document ||
        !branchStatus[branch.id] ||
        branchStatus[branch.id] !== "Approved"
      ) {
        allSubstepsVerified = false;
        break;
      }
    }
    return allSubstepsVerified ? "#84cc16" : "gray";
  };

  const getTimelineDotColor = (stepIndex) => {
    let allSubstepsVerified = true;
    for (const branch of plan.steps[stepIndex].branches) {
      if (
        !branch.document ||
        !branchStatus[branch.id] ||
        branchStatus[branch.id] !== "Approved"
      ) {
        allSubstepsVerified = false;
        break;
      }
    }
    return allSubstepsVerified ? "success" : "grey";
  };

  const getTimelineDotIcon = (stepIndex) => {
    let allSubstepsVerified = true;
    for (const branch of plan.steps[stepIndex].branches) {
      if (
        !branch.document ||
        !branchStatus[branch.id] ||
        branchStatus[branch.id] !== "Approved"
      ) {
        allSubstepsVerified = false;
        break;
      }
    }
    return allSubstepsVerified ? <CheckIcon size={8} /> : null;
  };
  return (
    <>
      <div className=" ">
        <Timeline
          position="right"
          sx={{ "& .MuiTimelineItem-root:before": { display: "none" } }}
        >
          {plan.steps.map((step, stepIndex) => (
            <TimelineItem key={step.id}>
              <TimelineSeparator>
                <TimelineDot
                  color={getTimelineDotColor(stepIndex)}
                  children={getTimelineDotIcon(stepIndex)}
                />
                {stepIndex < plan.steps.length - 1 && (
                  <TimelineConnector
                    sx={{
                      height: "50px",
                      backgroundColor: getTimelineConnectorColor(stepIndex),
                    }}
                  />
                )}
              </TimelineSeparator>
              <TimelineContent>
                <Accordion
                  defaultExpanded
                  style={{
                    width: "100%",
                    backgroundColor: "#F3F4F6",
                    boxShadow: "none",
                    borderRadius: "0.5rem",
                  }}
                >
                  {step.branches.length > 0 ? (
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon sx={{ color: "black" }} />}
                    >
                      <Typography
                        variant="h6"
                        sx={{ fontWeight: "bold", color: "black" }}
                      >
                        {step.title}
                      </Typography>
                    </AccordionSummary>
                  ) : (
                    <div className="p-4">
                      <Typography
                        variant="h6"
                        sx={{ fontWeight: "bold", color: "black" }}
                      >
                        {step.title}
                      </Typography>
                    </div>
                  )}
                  {step.branches.length > 0 && (
                    <AccordionDetails>
                      <div className="flex flex-col gap-y-2">
                        {step.branches.map((branch, branchIndex) => (
                          <div
                            key={branch.id}
                            className="ml-4 py-2 px-6 border bg-white rounded-lg"
                            onClick={() =>
                              handleOpenDrawer(branch, stepIndex, branchIndex)
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <Typography
                              variant="subtitle1"
                              sx={{ fontWeight: "bold", color: "black" }}
                            >
                              {`${stepIndex + 1}. ${branch.title}`}
                            </Typography>
                            <Typography
                              variant="body2"
                              color="grey"
                              sx={{ marginTop: "2px" }}
                            >
                              {branch.description}
                            </Typography>
                            <div
                              className="flex items-center justify-between mt-2"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleOpenDrawer(
                                  branch,
                                  stepIndex,
                                  branchIndex
                                );
                              }}
                            >
                              {branch.document && branch.document?.trim() ? (
                                <Chip
                                  size="small"
                                  icon={
                                    <ImageOutlinedIcon
                                      fontSize="small"
                                      sx={{ color: "black" }}
                                    />
                                  }
                                  label={
                                    branchStatus[branch.id]
                                      ? // ? "View Document"
                                        `View ${branch.file_type}`
                                      : `View ${branch.file_type}`
                                  }
                                  variant="outlined"
                                  sx={{ color: "black" }}
                                />
                              ) : (
                                <Typography
                                  // variant="subtitle1"
                                  variant="body2"
                                  sx={{
                                    color: "black",
                                    cursor: "pointer",
                                  }}
                                >
                                  No documents available
                                  {/* {getFileTypeName(branch.file_type)} */}
                                </Typography>
                              )}
                              {branch.document && branch.document?.trim() && (
                                <Typography
                                  variant="body2"
                                  sx={{
                                    color: getStatusColor(
                                      branchStatus[branch.id]
                                    ),
                                  }}
                                >
                                  Status:{" "}
                                  {branchStatus[branch.id] || "Unverified"}
                                </Typography>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    </AccordionDetails>
                  )}
                </Accordion>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </div>

      <DocumentDrawer
        open={isDrawerOpen}
        onClose={handleCloseDrawer}
        title="Document Verification"
      >
        <DocVerificationInnerPage
          branch={selectedBranch}
          onStatusChange={handleStatusChange}
          courseId={courseId}
          currentStatus={
            selectedBranch ? branchStatus[selectedBranch.id] : null
          }
        />
      </DocumentDrawer>
    </>
  );
}

export default ProcessingTimeline;
