import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Radio,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from "@mui/lab";
import { RiAttachment2 } from "react-icons/ri";

const PlanSteps = ({ plan}) => {
    console.log("er",plan);
      if (!plan || !plan.steps) {
        console.warn("Plan or steps are undefined", plan);
        return <div>No Plan Available</div>; 
      }
  return (
    <div className=" ">
      <Timeline
        position="right"
        sx={{ "& .MuiTimelineItem-root:before": { display: "none" } }}
      >
        {plan.steps.map((step, index) => (
          <TimelineItem key={step.id}>
            <TimelineSeparator>
              <TimelineDot color="primary" />
              {index < plan.steps.length - 1 && (
                <TimelineConnector
                  sx={{ height: "50px", backgroundColor: "#84cc16" }}
                />
              )}
            </TimelineSeparator>
            <TimelineContent>
              <Accordion
                defaultExpanded
                style={{
                  width: "100%",
                  backgroundColor: "#F3F4F6",
                  boxShadow: "none",
                  borderRadius: "0.5rem",
                }}
              >
                {step.branches.length > 0 ? (
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{ color: "black" }} />}
                  >
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: "bold", color: "black" }}
                    >
                      {step.title}
                    </Typography>
                  </AccordionSummary>
                ) : (
                  <div className="p-4">
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: "bold", color: "black" }}
                    >
                      {step.title}
                    </Typography>
                  </div>
                )}
                {step.branches.length > 0 && (
                  <AccordionDetails>
                    <div className="flex flex-col gap-y-2">
                      {step.branches.map((branch, index) => (
                        <div
                          key={branch.id}
                          className="ml-4 py-2 px-6 border bg-white rounded-lg"
                        >
                          <Typography
                            variant="subtitle1"
                            sx={{ fontWeight: "bold", color: "black" }}
                          >
                            {`${index + 1}. ${branch.title}`}
                          </Typography>
                          <Typography variant="body2" color="grey">
                            {branch.description}
                          </Typography>
                          {branch.is_upload === "true" && (
                            <div className="flex flex-row gap-x-2 items-center">
                              <Radio
                                checked
                                sx={{
                                  color: "#72FE18",
                                  "&.Mui-checked": {
                                    color: "#72FE18",
                                  },
                                }}
                              />
                              <Typography variant="body2" color="black">
                                Attachment Required
                              </Typography>
                              <Typography
                                variant="body2"
                                color="Black"
                                className="flex flex-row items-center"
                              >
                                <RiAttachment2 className="mr-1" />{" "}
                                {branch.file_type}
                              </Typography>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </AccordionDetails>
                )}
              </Accordion>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </div>
  );
};

export default PlanSteps;
