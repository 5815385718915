
import {
    AccessTimeOutlined,
    EventAvailableOutlined,
    HistoryOutlined
} from '@mui/icons-material';
import { Chip, Typography } from '@mui/material';


export const getScheduleStatus = (scheduledDate) => {
    if (!scheduledDate) return "";

    const currentDate = new Date();
    const compareDate = new Date(scheduledDate);

    currentDate.setHours(0, 0, 0, 0);
    compareDate.setHours(0, 0, 0, 0);

    if (compareDate < currentDate) {
        return "Past";
    } else if (compareDate.getTime() === currentDate.getTime()) {
        return "Today";
    } else {
        return "Upcoming";
    }
};

export const renderScheduleStatusChip = (scheduledDate) => {
    const status = getScheduleStatus(scheduledDate);

    const styleMap = {
        Past: {
            backgroundColor: '#e0e0e0',
            borderColor: '#607d8b',
            textColor: '#607d8b',
            icon: HistoryOutlined
        },
        Today: {
            backgroundColor: '#e8f5e9',
            borderColor: '#4caf50',
            textColor: '#4caf50',
            icon: EventAvailableOutlined
        },
        Upcoming: {
            backgroundColor: '#e3f2fd',
            borderColor: '#2196f3',
            textColor: '#2196f3',
            icon: AccessTimeOutlined
        },
        default: {
            backgroundColor: '#e0e0e0',
            borderColor: '#9e9e9e',
            textColor: '#757575',
            icon: AccessTimeOutlined
        }
    };

    const styles = styleMap[status] || styleMap.default;
    const IconComponent = styles.icon;

    return (
        <Chip
            icon={
                <IconComponent
                    sx={{ color: `${styles.textColor} !important` }}
                />
            }
            label={
                <Typography
                    variant="body2"
                    color={styles.textColor}
                    sx={{ fontWeight: 'bold' }}
                >
                    {status || "No Status"}
                </Typography>
            }
            sx={{
                backgroundColor: styles.backgroundColor,
                borderColor: styles.borderColor,
                color: styles.textColor,
                border: `1px solid ${styles.borderColor}`,
                width: '100%',
                borderRadius: '8px',
                justifyContent: 'center'
            }}
        />
    );
};

export const getLatestComment = (row, wordLimit = 5) => {
    // Handle case where row is undefined or null
    if (!row) return 'No Comments';

    // Get the comments array and ensure it's an array
    const comments = Array.isArray(row.comments) ? row.comments : [];

    // If no comments exist, return default message
    if (!comments || comments.length === 0) {
        return 'No Comments';
    }

    // Sort comments by time in descending order to get the latest
    // Parse the date strings properly to ensure correct sorting
    const sortedComments = [...comments].sort((a, b) => {
        const dateA = new Date(a.time.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$3-$2-$1'));
        const dateB = new Date(b.time.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$3-$2-$1'));
        return dateB - dateA;
    });

    // Get both message entries from the latest comment (they come in pairs)
    const latestCommentPair = sortedComments.slice(0, 2);

    // Get the second message which contains the actual comment (test, null, etc.)
    const actualComment = latestCommentPair[1]?.message;

    // If no message exists, return default
    if (!actualComment || actualComment.toLowerCase() === 'null') {
        return 'No Comments';
    }

    // Split the message into words and remove empty strings
    const words = actualComment.trim().split(/\s+/).filter(word => word.length > 0);

    // If message has fewer words than the limit, return the full message
    if (words.length <= wordLimit) {
        return actualComment;
    }

    // Return limited words with ellipsis
    return `${words.slice(0, wordLimit).join(' ')}...`;
};