import React, { useEffect, useRef, useState } from "react";
import {
  Badge,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  Avatar,
  Divider,
  Chip,
  Button,
} from "@mui/material";
import image from "../../plans/assests/images/Ai Profile image.png";
import { Bell } from "lucide-react";
import { listenForMessages, requestPermission } from "../firebase/Firebase";
import { useQuery, useQueryClient } from "react-query";
// import { fetchNotifications } from "../services/ChatService";

// import { fetchNotifications, fetchStudentMessages } from "../services/ChatService";
import { formatTimeAgo } from "../../plans/utils/formatTimeAgo";

const UNREAD_COUNT_KEY = "unreadNotificationsCount";
const NOTIFICATIONS_KEY = "Notifications";
const STORAGE_KEY = "notificationState";
const PROCESSED_MESSAGES_KEY = "processedMessageIds";

const NotificationComponent = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [notificationCount, setNotificationCount] = React.useState(0);
  const queryClient = useQueryClient();
  const messageListenersSet = useRef(false);

  const cleanupRef = useRef(null);

  // Load persisted count on component mount and set up storage listener
  useEffect(() => {
    // Initial load of notification count
    const persistedCount = localStorage.getItem("notification_count");
    if (persistedCount) {
      setNotificationCount(parseInt(persistedCount, 10));
      queryClient.setQueryData(UNREAD_COUNT_KEY, parseInt(persistedCount, 10));
    }

    // Add storage event listener to sync count across tabs
    const handleStorageChange = (e) => {
      if (e.key === "notification_count") {
        const newCount = parseInt(e.newValue, 10) || 0;
        setNotificationCount(newCount);
        queryClient.setQueryData(UNREAD_COUNT_KEY, newCount);
      }
    };

    window.addEventListener("storage", handleStorageChange);
    cleanProcessedMessages();

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const cleanProcessedMessages = () => {
    const processed = JSON.parse(
      localStorage.getItem(PROCESSED_MESSAGES_KEY) || "{}"
    );
    const oneHourAgo = Date.now() - 60 * 60 * 1000;

    const filtered = Object.entries(processed).reduce(
      (acc, [id, timestamp]) => {
        if (timestamp > oneHourAgo) {
          acc[id] = timestamp;
        }
        return acc;
      },
      {}
    );

    localStorage.setItem(PROCESSED_MESSAGES_KEY, JSON.stringify(filtered));
  };

  const isMessageProcessed = (messageId) => {
    const processed = JSON.parse(
      localStorage.getItem(PROCESSED_MESSAGES_KEY) || "{}"
    );
    return !!processed[messageId];
  };

  const markMessageProcessed = (messageId) => {
    const processed = JSON.parse(
      localStorage.getItem(PROCESSED_MESSAGES_KEY) || "{}"
    );
    processed[messageId] = Date.now();
    localStorage.setItem(PROCESSED_MESSAGES_KEY, JSON.stringify(processed));
  };

  // const { data: notifications = [] } = useQuery(
  //   NOTIFICATIONS_KEY,
  //   fetchNotifications,
  //   {
  //     staleTime: 1000 * 60,
  //   }
  // );

  const open = Boolean(anchorEl);

  const incrementNotificationCount = () => {
    const currentCount = parseInt(
      localStorage.getItem("notification_count") || "0",
      10
    );
    const newCount = currentCount + 1;

    localStorage.setItem("notification_count", newCount.toString());

    setNotificationCount(newCount);
    queryClient.setQueryData(UNREAD_COUNT_KEY, newCount);
  };

  const resetNotificationCount = () => {
    setNotificationCount(0);
    localStorage.setItem("notification_count", "0");
    queryClient.setQueryData(UNREAD_COUNT_KEY, 0);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    resetNotificationCount();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNotificationClick = (notification) => {
    markAsRead(notification.id);
    handleClose();
  };

  const markAsRead = (notificationId) => {
    queryClient.setQueryData(NOTIFICATIONS_KEY, (old) => {
      if (!old) return [];
      return old.map((n) =>
        n.id === notificationId ? { ...n, read: true } : n
      );
    });
  };

  const handleNewNotification = (payload) => {
    const messageId =
      payload.messageId ||
      payload.data?.notificationId ||
      Date.now().toString();

    if (isMessageProcessed(messageId)) {
      console.log("Duplicate message detected, skipping:", messageId);
      return;
    }

    markMessageProcessed(messageId);

    incrementNotificationCount();

    if (queryClient) {
      queryClient.invalidateQueries(NOTIFICATIONS_KEY);
    }
  };

  useEffect(() => {
    if (messageListenersSet.current) return;

    const setupNotifications = async () => {
      try {
        await requestPermission();

        // Handle foreground messages
        const unsubscribeForeground = listenForMessages((payload) => {
          console.log("Foreground message received:", payload);
          handleNewNotification(payload);
        });

        // Set up background message handler
        const messageHandler = (event) => {
          if (event.data?.type === "NOTIFICATION_RECEIVED") {
            console.log(
              "Background message received in component:",
              event.data.payload
            );
            handleNewNotification(event.data.payload);
          }
        };

        navigator.serviceWorker.addEventListener("message", messageHandler);

        // Store cleanup function
        cleanupRef.current = () => {
          unsubscribeForeground?.();
          navigator.serviceWorker.removeEventListener(
            "message",
            messageHandler
          );
        };

        messageListenersSet.current = true;
      } catch (error) {
        console.error("Error setting up notifications:", error);
      }
    };

    setupNotifications();

    // Cleanup on component unmount
    return () => {
      if (cleanupRef.current) {
        cleanupRef.current();
      }
    };
  }, []);

  return (
    <Box>
      <Button
        variant="outlined"
        onClick={handleClick}
        sx={{
          color: "black",
          backgroundColor: "transparent",
          mx: "8px",
          borderRadius: "8px",
          textTransform: "none",
          minWidth: "fit-content",
          lineHeight: "normal",
          display: "flex",
          alignItems: "center",
          gap: 1,
          padding: "8px 16px",
        }}
      >
        <Badge
          // badgeContent={notificationCount}
          color="secondary"
          overlap="circular"
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          sx={{
            "& .MuiBadge-badge": {
              fontSize: "0.7rem",
              minWidth: "18px",
              height: "18px",
              padding: "0 4px",
            },
          }}
        >
          <Bell size={20} />
        </Badge>
        <Typography
          variant="body2"
          // sx={{
          //   ml: 1,
          //   fontWeight: notificationCount > 0 ? 600 : 400,
          // }}
        >
          Notification
        </Typography>
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiMenu-paper": {
            mt: 2,
            overflow: "hidden",
            width: 400,
            maxHeight: 400,
            bgcolor: "white",
            overflowY: "auto",
            position: "relative",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            "&::-webkit-scrollbar": {
              display: "none",
            },
            scrollbarWidth: "none",

            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 14,
              height: 10,
              bgcolor: "white",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 10,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {/* {notifications && notifications.length > 0 ? (
          notifications.map((notification) => (
            <Box key={notification.id} sx={{ px: 2 }}>
              <MenuItem
                onClick={() => handleNotificationClick(notification)}
                sx={{
                  py: 0,
                }}
              >
                <Avatar
                  src={image}
                  alt="Notification"
                  sx={{ mr: 2, width: 40, height: 40 }}
                />
                <Box sx={{ flex: 1 }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      fontWeight={!notification.read ? 700 : 400}
                      color="black"
                    >
                      {notification.data?.sender_type || "Unknown Sender"}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <Typography
                      variant="body2"
                      color="black"
                      sx={{
                        flex: 1,
                        whiteSpace: "normal",
                        wordBreak: "break-word",
                        fontWeight: !notification.read ? 500 : 400,
                      }}
                    >
                      {notification.data?.message || "No message content"}
                    </Typography>
                    {notification.type === "chat message" && (
                      <Chip label="Chat" color="primary" size="small" />
                    )}
                  </Box>
                  <Typography
                    variant="caption"
                    color="black"
                    sx={{ display: "block", mt: 1 }}
                  >
                    {formatTimeAgo(notification.created_at)}
                  </Typography>
                </Box>
              </MenuItem>
              <Divider sx={{ backgroundColor: "lightgrey" }} />
            </Box>
          ))
        ) : ( */}
          <MenuItem onClick={handleClose}>
            <Typography variant="body2" color="Black">
              No new notifications
            </Typography>
          </MenuItem>
        {/* // )} */}
      </Menu>
    </Box>
  );
};

export default NotificationComponent;
