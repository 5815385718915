import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    tableData: [],
    filterValues: {},
    lastVisitedFrom: null,
    paginationModel: {
        page: 0,
        pageSize: 10
    }
};

const reportSlice = createSlice({
    name: 'report',
    initialState,
    reducers: {
        setTableData: (state, action) => {
            state.tableData = action.payload;
        },
        setFilterValues: (state, action) => {
            state.filterValues = action.payload;
        },
        setLastVisitedFrom: (state, action) => {
            state.lastVisitedFrom = action.payload;
        },
        setPaginationModel: (state, action) => {
            state.paginationModel = action.payload;
        },
        resetState: (state) => {
            state.tableData = [];
            state.filterValues = {};
            state.lastVisitedFrom = null;
            state.paginationModel = {
                page: 0,
                pageSize: 10
            };
        }
    }
});

export const {
    setTableData,
    setFilterValues,
    setLastVisitedFrom,
    setPaginationModel,
    resetState
} = reportSlice.actions;


export default reportSlice.reducer;