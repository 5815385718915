// Create plan
export const addPlan = "/plan/add"

// get Countries
export const getCountries = "/country/getall"
// Add new Country
export const addNewCountry = "/country/create"
// getPlansbyCountryID
export const getPlansByCountryId = (countryId) => `/plan/getbycountry/${countryId}`

// get applied Course
export const getAppliedCourse = "/appliedcourse/get"

// submit plan to applied course
export const addPlantoAppliedCourse = "/appliedcourse/allocateplan"
// get plan by applied couurse
export const planByAppliedCourse = (cousreId) => `/plan/getbyapplycourse/${cousreId}`
// plan execution
export const planExecution = `/plan-execution/change-status`
// get Planstatus after verifying
export const getPlanStatus = "/plan/branchbyid/getbyapplycourse"

// get the staff data
export const getStaffs = "/user/getstaffs"

//assign staff to students
export const postStaff = "/user/updatelead"

// get the messages for that student_id
export const getMessages = (studentId) => `/chat/getmessages/${studentId}`

// get notification
export const getNotification = "/employee/notification/get"

