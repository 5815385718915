import axiosInstance from "./axiosinstance";
import { getAppliedCourse,addPlantoAppliedCourse, getStaffs, postStaff } from "./api";


export const fetchAppliedCourseData = async() => {
    const response = await axiosInstance.get(getAppliedCourse)
    console.log("yui", response);
    
    return response.data.responsedata
}

export const fetchCourseDetailsById = async (id) => {
    // console.log("id",id);
    
    const courses = await fetchAppliedCourseData(); 
    const courseDetails = courses.find(course => course.id === Number(id)); 
// console.log("details",courseDetails);

    if (courseDetails) {
        return courseDetails; 
    } else {
        throw new Error('Course not found');
    }
};

export const allocatePlanToAppliedCourse = async (courseId, planId) => {
    try {
        
        const response = await axiosInstance.post(addPlantoAppliedCourse, {
            id: courseId,
            plan_id: planId,
        })
        // if (response.data && response.data.responsecode === "200") {
        //     console.log("Allocated ");
            
        // }
        return response.data;
    } catch (error) {
       
        console.error('An error occurred while allocating the plan:', error);
            throw error;
        
    }
}

export const getAllStaff = async () => {
    try {
        
        const response = await axiosInstance.post(getStaffs)
        return response.data.responsedata
    } catch (error) {
        console.error('An error occurred while fetching staffs data:', error);
        throw error;

    }
    
}
export const assignStaff = async (staffid, leadid) => {
   
    try {
        const response = await axiosInstance.post(postStaff, {
            staffid,
            leadid,
            status: 3,
            isgivenuplead: 0,
        })
        // console.log("correct", response.data);
        
        return response.data
        
    } catch (error) {
        console.error('An error occurred while assigning staffs :', error);
        throw error;
    }
}