import axiosInstance from './axiosinstance';
import { addNewCountry, getCountries, getPlansByCountryId } from './api';

let countriesCache = [];

export const getAllCountries = async () => {
    const response = await axiosInstance.get(getCountries)
    countriesCache = response.data.responsedata;
    return countriesCache;
}

export const addCountries = async (countryData) => {
    
    
    const response = await axiosInstance.post(addNewCountry, countryData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
    console.log("corrects", response.data);
    
    return response.data
}

export const getCountryById = (id) => {
    console.log("Input ID:", id); 
    console.log("Countries Cache:", countriesCache);
    
    const country = countriesCache.find((country) => String(country.id) === String(id));
    console.log("Found Country:", country);
    return country || null; 
    
};

export const getPlansByCountryID = async (countryId) => {
    try {
        const response = await axiosInstance.get(getPlansByCountryId(countryId));
        return response.data.responsedata;
    } catch (error) {
        console.error("Error fetching plans:", error);
        return [];
    }
};

export const fetchPlansByCountry = async (countryName) => {
    try {
       
        if (countriesCache.length === 0) {
            await getAllCountries();
        }

        const country = countriesCache.find(country => country.name === countryName);
        if (country) {
            const plansData = await getPlansByCountryID(country.id);
            
            return plansData; 
        } else {
            console.warn("Country not found in cache");
            return []; 
        }
    } catch (error) {
        console.error("Error fetching plans by country:", error);
        return []; 
    }
};