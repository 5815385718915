import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import { tokens } from "./theme";
import { LogOut, ArrowRight } from 'lucide-react';
import { ArrowBack } from '@mui/icons-material';
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import NotificationComponent from "../pages/plans/components/NotificationComponent";
import logout from "../pages/plans/assests/images/Logout Icon.png"
import { resetState } from '../redux/reportSlice';
import { useDispatch } from "react-redux";


const Header = ({ title, subTitle, badgeCount }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(resetState());
    // Perform logout logic here
    navigate(`/`, { replace: true });

    setOpen(false);
  };

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Box display="flex" alignItems="center" sx={{ p: "20px" }}>
        <Button
          onClick={() => navigate(-1)}
          sx={{
            minWidth: "40px",
            height: "40px",
            borderRadius: "50%",
            backgroundColor: "#ffffff",
            color: "#000000",
            mr: "12px",
            '&:hover': { backgroundColor: "#f0f0f0" },
          }}
        >
          <ArrowBack />
        </Button>
        <Box>
          <Typography
            variant="h3"
            color={colors.bg[100]}
            fontWeight="bold "
            sx={{ m: "0 0 5px 0" }}
          >
            {title}
          </Typography>
          <Typography variant="h5" color={colors.textcolor[100]}>
            {subTitle}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        {/* <Button
          variant="outlined"
          sx={{
            color: "black", 
            backgroundColor: "transparent",
            mx: "8px",
            borderRadius: "8px",
            // padding: "8px 16px",
            textTransform: "none",
            minWidth: "fit-content",
            lineHeight: "normal",
          }}
          startIcon={<NotificationComponent badgeCount={badgeCount} sx={{ fontSize: 20 }} />}
        >
          Notification
        </Button> */}
        <NotificationComponent />
        {/* Logout Button */}
        <Button
          variant="contained"
          sx={{
            backgroundColor: "Black",
            color: "#ffffff",
            mx: "8px",
            borderRadius: "8px",
            padding: "6px 18px",
            textTransform: "none",
            minWidth: "fit-content"
          }}
          startIcon={<LogOut size={20} />}
          onClick={() => setOpen(true)}
        >
          Logout
        </Button>


        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          sx={{
            "& .MuiDialogContent-root": {
              backgroundColor: "#ffffff",
              color: "#000000",
              gap: '15px',
              display: "flex",
              flexDirection: 'column',
              justifyContent: "center",
              alignItems: "center",
            },
            "& .MuiDialog-paper": {
              width: "500px",
              height: "260px",
              borderRadius: "16px",

            }
          }}
        >
          <DialogContent sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            padding: "24px 16px",
            gap: "24px"
          }}>
            <Box sx={{
              width: "50px",
              height: "50px",
              borderRadius: "50%",
              // border: "2px solid #000",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}>
              <img
                src={logout}
                alt="log_out"
                className=" h-[4.5rem] object-cover rounded-lg"
              // style={{ zIndex: 0 }}
              />
            </Box>

            <Typography variant="h5" sx={{
              fontSize: "24px",
              fontWeight: "600",
            }}>
              Logout
            </Typography>

            <Typography sx={{
              color: "Black",
              fontSize: "20px",
              mb: '7px'
            }}>
              Are you sure want to logout ?
            </Typography>

            <Box sx={{
              display: "flex",

              gap: "12px",
              width: "55%",

            }}>
              <Button
                fullWidth
                variant="outlined"
                onClick={() => setOpen(false)}
                sx={{
                  borderRadius: "6px",
                  textTransform: "none",
                  border: "1px solid grey",
                  color: "red",
                  fontSize: "16px",
                  // "&:hover": {
                  //   border: "1px solid #e0e0e0",
                  //   backgroundColor: "#f5f5f5"
                  // }
                }}
              >
                Cancel
              </Button>
              <Button
                fullWidth
                variant="contained"
                onClick={handleLogout}
                sx={{
                  borderRadius: "6px",
                  textTransform: "none",
                  backgroundColor: "black",
                  fontSize: "16px",
                  // "&:hover": {
                  //   backgroundColor: "#333"
                  // }
                }}
              >
                Logout
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      </Box>
    </Box>
  );
};

export default Header;
