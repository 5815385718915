// firebase.js
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken,onMessage } from "firebase/messaging"; // For notifications


// Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCKsvTe0MQJdKyF5SvHN-8cjNcAMhIZugQ",
    authDomain: "hrms-909a0.firebaseapp.com",
    projectId: "hrms-909a0",
    storageBucket: "hrms-909a0.appspot.com",
    messagingSenderId: "574710470321",
    appId: "1:574710470321:web:34e3c993b75661a8767490",
    measurementId: "G-NREVZ9B3KH"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
console.log("Firebase initialized:", app);
const analytics = getAnalytics(app);
const messaging = getMessaging(app);
console.log("Firebase Messaging initialized:", messaging);

// Request permission for notifications and register VAPID key
console.log("Notification permission:", Notification.permission);
export const requestPermission = async () => {
    console.log("hello", Notification.permission);
    
    if (Notification.permission === "default") {
        const permission = await Notification.requestPermission();
        console.log("Requested notification permission:", permission);
        if (permission !== "granted") {
            console.error("Notification permission denied");
            console.log("Requested notification permission:", permission);
            return;
        }
    }

    try {
        const token = await getToken(messaging, {
            vapidKey: "BB89rIBVrisbgnByWxQIFWTnpWKZgFowQwOgQPpvMvRH4FeGKRXTngVR9Z58SNWikOnLFM8J-41GTBbacyR4zBA",
            serviceWorkerRegistration: await navigator.serviceWorker.register("/firebase-messaging-sw.js")
                .then((registration) => {
                    console.log("Service Worker registered:", registration);
                })
                .catch((error) => console.error("Service Worker registration failed:", error))
        });
        if (token) {
            console.log("FCM Token:", token); 
            return token
        } else {
            console.error("No registration token available. Request permission to generate one.");
        }
    } catch (error) {
        console.error("Error retrieving token:", error);
    }
};

export const listenForMessages = (onReceiveMessage) => {
    onMessage(messaging, (payload) => {
        console.log("Message received in foreground: ", payload);
        if (onReceiveMessage) {
            onReceiveMessage(payload);
        }
    });
};

export const onMessageReceived = (onReceiveMessage) => {
  navigator.serviceWorker.addEventListener("message", (event) => {
    if (event.data.notification) {
      const { title, body } = event.data.notification;
      console.log("Message received in background: ", { title, body });
      if (onReceiveMessage) {
        onReceiveMessage({ notification: { title, body } });
      }
    }
  });
};

export { app, analytics, messaging, };