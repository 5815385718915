import { useState } from "react";
import { Typography, Button,Box } from "@mui/material";
import { useMutation } from "react-query";
import { toast } from "react-hot-toast";
import { planExecutionFunction } from "../services/PlansExecutionService";
import DownloadIcon from "@mui/icons-material/Download";


function DocVerificationInnerPage({
  branch,
  onStatusChange,
  courseId,
  currentStatus,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const isVerified =
    currentStatus === "Approved" || currentStatus === "Rejected";

  const mutation = useMutation(
    ({ applyCourse, stepBranch, status }) =>
      planExecutionFunction(applyCourse, stepBranch, status),
    {
      onMutate: () => {
        setIsLoading(true);
      },
      onSettled: () => {
        setIsLoading(false);
      },
      onSuccess: (data) => {
        if (data.responsecode === "200") {
          toast.success("Status updated successfully!");
          onStatusChange(branch.id,data.status);
        } else {
          toast.error("Failed to update status.");
        }
      },
      onError: (error) => {
        toast.error("Error verifying the step.");
      },
    }
  );

  const handleStatusChange = (status) => {
    if (!isVerified) {
      mutation.mutate({
        applyCourse: courseId,
        stepBranch: branch.id,
        status: status,
      });
    }
  };

  const hasDocument = branch.document && branch.document.trim() !== "";
const documentUrl = `https://drive.google.com/file/d/${branch.document}/preview`;
    const directDownloadUrl = `https://drive.google.com/uc?export=download&id=${branch.document}`;
    
      const handleDownload = () => {
    
    const previewWindow = window.open(documentUrl, "_blank");

    
    setTimeout(() => {
      const link = document.createElement("a");
      link.href = directDownloadUrl;
      link.download = `document_${branch.document}`;
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }, 300); 
  };
    
const getStatusColor = (status) => {
  switch (status) {
    case "Approved":
      return "green";
    case "Rejected":
      return "red";
    default:
      return "gray";
  }
};
  return (
    <div>
      <Box
        sx={{
          marginBottom: "15px",
          height: "650px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "10px",
          backgroundColor: "#e0e0e0",
          borderRadius: "8px",
        }}
      >
        {/* Download Button */}

        {hasDocument ? (
          branch.file_type === "pdf" ? (
            <embed
              src={documentUrl}
              type="application/pdf"
              title="Document Preview"
              style={{
                width: "100%",
                height: "100%",
                border: "none",
                borderRadius: "8px",
              }}
            />
          ) : (
            <img
              src={`https://drive.google.com/thumbnail?id=${branch.document}&sz=500-h500`}
              alt="Document"
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
                objectFit: "contain",
              }}
            />
          )
        ) : (
          <Typography align="center" color="grey" sx={{ padding: "20px" }}>
            No document to show
          </Typography>
        )}
      </Box>
      <div className="flex justify-between items-center">
        {/* Display Verification Status if Verified */}
        {isVerified && (
          <Typography
            variant="subtitle1"
            sx={{
              color: getStatusColor(currentStatus),
              fontWeight: "bold",
              backgroundColor: "white",
              padding: "8px 16px",
              borderRadius: "4px",
            }}
          >
            Document {currentStatus}
          </Typography>
        )}

        {/* Approval and Rejection Buttons if Not Verified */}
        {!isVerified && hasDocument && (
          <div className="flex gap-2">
            <Button
              variant="contained"
              color="success"
              onClick={() => handleStatusChange("Approved")}
              disabled={isLoading}
            >
              Approve
            </Button>
            <Button
              variant="contained"
              onClick={() => handleStatusChange("Rejected")}
              disabled={isLoading}
            >
              Reject
            </Button>
          </div>
        )}

        {/* Download Button - Always Displayed if Document Exists */}
        {hasDocument && (
          <a href={documentUrl} download={`document_${branch.document}`}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<DownloadIcon />}
              onClick={handleDownload}
              sx={{ textTransform: 0 }}
            >
              Download
            </Button>
          </a>
        )}
      </div>
    </div>
  );
}

export default DocVerificationInnerPage;
