import { useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "./theme";
import {
 
  // DifferenceOutlined,
  HowToRegRounded,
} from "@mui/icons-material";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import PieChartOutlineOutlinedIcon from "@mui/icons-material/PieChartOutlineOutlined";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import { DifferenceOutlined } from "@mui/icons-material";
import { useEffect } from "react";
import "./sideBar.css"
import Tooltip from "@mui/material/Tooltip";

// const Item = ({ title, to, icon, selected, setSelected }) => {
//   const theme = useTheme();
//   const colors = tokens(theme.palette.mode);
//   return (
//     <MenuItem
//       active={selected === title}
//       style={{
//         color: colors.grey[100],
//       }}
//       onClick={() => setSelected(title)}
//       icon={icon}
//     >
//       <Typography>{title}</Typography>
//       <Link to={to} />
//     </MenuItem>
//   );
// };

const SideBar = ({ userId }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [activeItem, setActiveItem] = useState("Dashboard");


  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleMenuClick = (path) => {
    setActiveItem(path);

    navigate(path);
  };

  // Set initial active item based on current path
  useEffect(() => {
    const currentPath = window.location.pathname;
    setActiveItem(currentPath);
  }, []);

  const menuItems = [
    {
      items: [{ title: "Dashboard", to: `/u/${userId}/dashboard`, icon: <HomeOutlinedIcon /> }],
    },
    {
      section: "Data",
      items: [
        { title: "Leads", to: `/u/${userId}/task`, icon: <ContactsOutlinedIcon /> },
        // { title: "Meta & Other Leads", to: `/u/${userId}/metaleads`, icon: <DifferenceOutlined /> },
        { title: "Add Leads", to: `/u/${userId}/addlead`, icon: <ReceiptOutlinedIcon /> },
        { title: "Applied Course", to: `/u/${userId}/appliedcourse`, icon: <HowToRegRounded /> },
        { title: "Performance", to: `/u/${userId}/perfomance`, icon: <DifferenceOutlined /> },
        // { title: "Plans", to: `/u/${userId}/${userName}/countries`, icon: <EditNoteRounded /> },
        { title: "Reports", to: `/u/${userId}/report`, icon: <ReceiptOutlinedIcon /> },
        // { title: "Call Status", to: `/u/${userId}/${userName}/callrecords`, icon: <GraphicEqRounded /> },
      ],
    },
    {
      section: "Human Resources",
      items: [
        {
          title: "My Profile",
          to: `/u/${userId}/staffs`,
          icon: <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="currentColor">
            <path d="M40-160v-112q0-34 17.5-62.5T104-378q62-31 126-46.5T360-440q66 0 130 15.5T616-378q29 15 46.5 43.5T680-272v112H40Zm720 0v-120q0-44-24.5-84.5T666-434q51 6 96 20.5t84 35.5q36 20 55 44.5t19 53.5v120H760ZM360-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47Z" />
          </svg>

        },
        {
          title: "Holidays",
          to: `/u/${userId}/attandence`,
          icon: <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="currentColor">
            <path d="M200-80q-33 0-56.5-23.5T120-160v-560q0-33 23.5-56.5T200-800h40v-80h80v80h320v-80h80v80h40q33 0 56.5 23.5T840-720v560q0 33-23.5 56.5T760-80H200Zm0-80h560v-400H200v400Zm0-480h560v-80H200v80Zm280 240q-17 0-28.5-11.5T440-440q0-17 11.5-28.5T480-480q17 0 28.5 11.5T520-440q0 17-11.5 28.5T480-400Z" />
          </svg>

        },
        {
          title: "Calendar",
          to: `/calendar`,
          icon: <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="currentColor">
            <path d="M280-280h280v-80H280v80Zm0-160h400v-80H280v80Zm0-160h400v-80H280v80Zm-120 400q-33 0-56.5-23.5T80-280v-480q0-33 23.5-56.5T160-840h640q33 0 56.5 23.5T880-760v480q0 33-23.5 56.5T800-200H160Z" />
          </svg>

        },
        {
          title: "Events",
          to: `/faq`,
          icon: <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="currentColor">
            <path d="M240-80q-50 0-85-35t-35-85v-120h120v-560h480v680q0 50-35 85t-85 35H240Z" />
          </svg>

        },
        

        // {
        //   title: "Add Employee",
        //   to: `/u/${userId}/staff/create`,
        //   icon: < GroupAddRounded />
        // },
        // {
        //   title: "Manage Employees",
        //   to: `/u/${userId}/staffs`,
        //   icon: < PersonOutlinedIcon />

        // },
        // {
        //   title: "Calendar",
        //   to: "/calendar",
        //   icon: < CalendarTodayOutlinedIcon />,
        // },
        // {
        //   title: "Events",
        //   to: "/faq",
        //   icon: < HelpOutlineOutlinedIcon />,
        // },
      ],
    },
    {
      section: "Support",
      items: [
        {
          title: "Bar Chart",
          to: "/bar",
          icon: < BarChartOutlinedIcon />,
        }, {
          title: "Call Support",
          to: "/pie",
          icon: < PieChartOutlineOutlinedIcon />,
        }, {
          title: "Videos",
          to: "/line",
          icon: < TimelineOutlinedIcon />,
        }, {
          title: "FAQ",
          to: "/geography",
          icon: < MapOutlinedIcon />
        },
      ],
    }
  ];

  return (
    // <Box
    //   maxHeight="100vh"
    //   sx={{
    //     "& .pro-sidebar-inner": {
    //       background: `${colors.bg[100]} !important`,
    //     },
    //     "& .pro-icon-wrapper": {
    //       backgroundColor: "transparent !important",
    //     },
    //     "& .pro-inner-item": {
    //       padding: "5px 35px 5px 20px !important",
    //     },
    //     "& .pro-inner-item:hover": {
    //       color: `${colors.greenAccent[100]} !important`,
    //     },
    //     "& .pro-menu-item.active": {
    //       color: `${colors.bg[100]} !important`,
    //       background: `${colors.greenAccent[500]} !important`,
    //       borderRadius: "25px",
    //     },
    //   }}
    // >
    //   <ProSidebar collapsed={isCollapsed}>
    //     <Menu iconShape="square">
          
    //       <MenuItem
    //         onClick={() => setIsCollapsed(!isCollapsed)}
    //         icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
    //         style={{
    //           margin: "10px 0 20px 0",
    //           color: colors.grey[100],
    //         }}
    //       >
    //         {!isCollapsed && (
    //           <Box
    //             display="flex"
    //             justifyContent="space-between"
    //             alignItems="center"
    //             ml="15px"
    //           >
    //             <Typography variant="h4" color={colors.grey[100]}>
    //               Mavex Mentr
    //             </Typography>
    //             <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
    //               <MenuOutlinedIcon />
    //             </IconButton>
    //           </Box>
    //         )}
    //       </MenuItem>

    //       <Box paddingLeft={isCollapsed ? undefined : "10%"}>
    //         <Item
    //           title="Dashboard"
    //           to={`/u/${userId}/dashboard`}
    //           icon={<HomeOutlinedIcon />}
    //           selected={selected}
    //           setSelected={setSelected}
    //         />

    //         <Typography
    //           variant="h6"
    //           color={colors.grey[300]}
    //           sx={{ m: "15px 0 5px 20px" }}
    //         >
    //           Data
    //         </Typography>

    //         <Item
    //           title="Leads"
    //           to={`/u/${userId}/task`}
    //           icon={<ContactsOutlinedIcon />}
    //           selected={selected}
    //           setSelected={setSelected}
    //         />
    //         <Item
    //           title="Perfomance"
    //           to={`/u/${userId}/perfomance`}
    //           icon={<DifferenceOutlined />}
    //           selected={selected}
    //           setSelected={setSelected}
    //         />
    //         <Item
    //           title="Add Leads"
    //           to={`/u/${userId}/addlead`}
    //           icon={<ReceiptOutlinedIcon />}
    //           selected={selected}
    //           setSelected={setSelected}
    //         />
    //         <Item
    //           title="Reports"
    //           to={`/u/${userId}/report`}
    //           icon={<ReceiptOutlinedIcon />}
    //           selected={selected}
    //           setSelected={setSelected}
    //         />

    //         <Typography
    //           variant="h6"
    //           color={colors.grey[300]}
    //           sx={{ m: "15px 0 5px 20px" }}
    //         >
    //           Human Resources
    //         </Typography>
    //         <Item
    //           title="My Profile"
    //           to={`/u/${userId}/staffs`}
    //           icon={<PeopleOutlinedIcon />}
    //           selected={selected}
    //           setSelected={setSelected}
    //         />
    //         <Item
    //           title="Holidays"
    //           to={`/u/${userId}/attandence`}
    //           icon={<PersonOutlinedIcon />}
    //           selected={selected}
    //           setSelected={setSelected}
    //         />
    //         <Item
    //           title="Calendar"
    //           to="/calendar"
    //           icon={<CalendarTodayOutlinedIcon />}
    //           selected={selected}
    //           setSelected={setSelected}
    //         />
    //         <Item
    //           title="Events"
    //           to="/faq"
    //           icon={<HelpOutlineOutlinedIcon />}
    //           selected={selected}
    //           setSelected={setSelected}
    //         />

    //         <Typography
    //           variant="h6"
    //           color={colors.grey[300]}
    //           sx={{ m: "15px 0 5px 20px" }}
    //         >
    //           Support
    //         </Typography>
    //         <Item
    //           title="Bar Chart"
    //           to="/bar"
    //           icon={<BarChartOutlinedIcon />}
    //           selected={selected}
    //           setSelected={setSelected}
    //         />
    //         <Item
    //           title="Call Support"
    //           to="/pie"
    //           icon={<PieChartOutlineOutlinedIcon />}
    //           selected={selected}
    //           setSelected={setSelected}
    //         />
    //         <Item
    //           title="Videos"
    //           to="/line"
    //           icon={<TimelineOutlinedIcon />}
    //           selected={selected}
    //           setSelected={setSelected}
    //         />
    //         <Item
    //           title="FAQ"
    //           to="/geography"
    //           icon={<MapOutlinedIcon />}
    //           selected={selected}
    //           setSelected={setSelected}
    //         />
    //       </Box>
    //     </Menu>
    //   </ProSidebar>
    // </Box>

    <div
      className={`${isCollapsed ? "w-20" : "w-72"
        } h-screen bg-black text-white flex flex-col transition-all duration-300 relative`}
    >
      <div className="flex justify-between items-center p-5 ">
        <h2 className={`${isCollapsed ? "hidden" : "text-2xl font-bold"} text-center`}>
          Mavex Mentr
        </h2>
        <button onClick={toggleSidebar} className="p-1 hover:bg-gray-700 rounded-md transition-colors">
          <MenuOutlinedIcon />
        </button>
      </div>


      <nav className="flex-1 scrollbar-hide overflow-y-auto py-4 space-y-2">
        {menuItems.map((menu, index) => (
          <div key={index} className="px-4 my-2 mb-6">
            {!isCollapsed && (
              <p className="text-neutral-400 text-base font-medium mb-3 px-2" >{menu.section}</p>
            )}
            {menu.items.map((item, itemIndex) => (
              <Tooltip
                key={itemIndex}
                title={isCollapsed ? item.title : ""}
                placement="right"
                arrow
              >

              <button
                key={itemIndex}
                onClick={() => handleMenuClick(item.to)}
                className={`w-full flex items-center space-x-4 px-3 py-2.5 rounded-md transition-all duration-200 ${activeItem === item.to ? "bg-white text-black font-medium" : ""} `}
              >
                <span className={`flex-shrink-0 ${activeItem === item.to ? "animate-swing" : "hover:animate-swing"}`}>{item.icon}</span>
                {!isCollapsed && <span className="text-left flex-1">{item.title}</span>}
              </button>
              </Tooltip>
            ))}
          </div>
        ))}
      </nav>
    </div>
  );
};

export default SideBar;
