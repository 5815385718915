import axios from 'axios';
import { BASEURL } from '../../../data/api';

const axiosInstance = axios.create({
    // baseURL: process.env.REACT_APP_BASE_URL ,
    baseURL: BASEURL,
    headers: {
        'Content-Type': 'application/json',
    },
});

axiosInstance.interceptors.request.use(
    (config) => {

        const token = localStorage.getItem('authToken');
        // const token =`eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1dWlkIjoiMDU1NzUzODc5MjkwODc4In0.-p9hPK4bwyDL4lJpSqtgl7V13Xtx4AQiiDcmoehNR3Y`
        // console.log('token here',token);

        if (token) {
            config.headers.Authorization = `${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {

        if (error.response && error.response.status === 401) {

        }
        return Promise.reject(error);
    }
);

export default axiosInstance;