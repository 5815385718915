import React, { useState, useEffect, useRef } from "react";
import image from "../../plans/assests/images/Ai Profile image.png";
import { IoSendSharp } from "react-icons/io5";
// import useWebSocket from "./hooks/useWebsocket";
import { useQuery, useQueryClient } from "react-query";
import { fetchChatMessages } from "../services/ChatService"; 
import moment from "moment"; 

function ConversationComponent({ studentId }) {
  const [message, setMessage] = useState("");
  const [currentDateHeader, setCurrentDateHeader] = useState("");
  const queryClient = useQueryClient();
  const messagesEndRef = useRef(null);
  const chatContainerRef = useRef(null);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
const dateHeaderRefs = useRef({});
  // const role = "admin";
  // const token = localStorage.getItem("authToken");

  // const { send } = useWebSocket(role, studentId, token);
  
  const getDateDisplay = (date) => {
    const messageDate = moment(date);
    const today = moment().startOf("day");
    const yesterday = moment().subtract(1, "days").startOf("day");

    if (messageDate.isSame(today, "day")) {
      return "Today";
    } else if (messageDate.isSame(yesterday, "day")) {
      return "Yesterday";
    } else {
      return messageDate.format("MMMM D, YYYY");
    }
  };

  const { data: messages = [] } = useQuery(
    ["chatMessages", studentId],
    () => fetchChatMessages(studentId),
    {
      // refetchInterval: 1000,
      select: (data) =>
        data
          .map((msg) => {
            let text;
            try {
              const parsedMessage = JSON.parse(msg.message);
              text =
                typeof parsedMessage === "object" && parsedMessage.message
                  ? parsedMessage.message
                  : parsedMessage;
            } catch (e) {
              text = msg.message;
            }
              if (typeof text === "object") {
                text = JSON.stringify(text);
              }
            const formattedTime = moment
              .parseZone(msg.sent_at)
              .format("hh:mm A");

            return {
              text,
              sender: msg.sender_type === "student" ? "other" : "self",
              id: msg.id,
              timestamp: formattedTime,
              fullDate: msg.sent_at,
            };
          })
          .sort(
            (a, b) =>
              moment(b.fullDate).valueOf() - moment(a.fullDate).valueOf()
          )
          .reverse(),
    }
  );

  
  const groupedMessages = messages.reduce((groups, message) => {
    const date = moment(message.fullDate).startOf("day").valueOf();
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(message);
    return groups;
  }, {});
  const handleScroll = () => {
    if (!chatContainerRef.current) return;

    const scrollContainer = chatContainerRef.current;
    const scrollTop = scrollContainer.scrollTop;
    const containerTop = scrollContainer.getBoundingClientRect().top;

    
    let currentHeader = null;
    Object.entries(dateHeaderRefs.current).forEach(([date, ref]) => {
      if (!ref) return;
      const headerRect = ref.getBoundingClientRect();
      const headerTop = headerRect.top - containerTop;

      if (headerTop <= 60) {
        
        currentHeader = date;
      }
    });

    if (currentHeader) {
      setCurrentDateHeader(getDateDisplay(Number(currentHeader)));
    }
  };

 
  useEffect(() => {
    const container = chatContainerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
      handleScroll(); 
    }
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [messages]);

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    if (messages.length > 0 && isInitialLoad) {
      scrollToBottom();
      setIsInitialLoad(false);
    }
  }, [messages, isInitialLoad]);

  useEffect(() => {
    if (!isInitialLoad) {
      scrollToBottom();
    }
  }, [messages.length]);

  const handleInputChange = (e) => {
    setMessage(e.target.value);
  };

  // const handleSendMessage = async () => {
  //   if (message.trim()) {
  //     try {
  //       await send(message);
  //       setMessage("");
  //       queryClient.invalidateQueries(["chatMessages", studentId]);
  //     } catch (error) {
  //       console.error("Error sending message:", error);
  //     }
  //   }
  // };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      // handleSendMessage();
    }
  };

  return (
    <div className="bg-white px-4 py-2 rounded-xl shadow h-full flex flex-col">
      {/* Header */}
      <div className="flex items-center mb-2 flex-none">
        <img src={image} alt="User" className="rounded-full w-8 h-8 mr-2" />
        <h2 className="text-[13px] font-semibold text-black">Greeshma B</h2>
      </div>
      <div className="w-full border-t border-gray-200"></div>
      {/* Sticky Date Header */}
      {currentDateHeader && (
        <div className="sticky top-0 z-10 flex justify-center py-2 bg-white/95 backdrop-blur-sm">
          <div className="bg-gray-200 rounded-lg px-3 py-1 text-xs text-gray-600">
            {currentDateHeader}
          </div>
        </div>
      )}
      {/* Messages Container */}
      <div
        className="flex-1 overflow-y-auto scrollbar-hide my-4"
        ref={chatContainerRef}
      >
        <div className="flex flex-col space-y-4">
          {Object.entries(groupedMessages).map(([date, dateMessages]) => (
            <div key={date} className="space-y-4">
              {/* Date Header */}
              {/* <div className="flex justify-center">
                <div className="bg-gray-200 rounded-lg px-3 py-1 text-xs text-gray-600">
                  {getDateDisplay(Number(date))}
                </div>
              </div> */}
              <div
                ref={(el) => (dateHeaderRefs.current[date] = el)}
                className="h-0 w-full"
              />

              {/* Messages for this date */}
              {dateMessages.map((msg) => (
                <div
                  key={msg.id}
                  className={`flex space-x-2 ${
                    msg.sender === "self" ? "justify-end" : "justify-start"
                  }`}
                >
                  {msg.sender === "other" && (
                    <div className="bg-gray-200 w-7 h-7 rounded-full flex-shrink-0">
                      <img
                        src={image}
                        alt="User"
                        className="rounded-full w-7 h-7"
                      />
                    </div>
                  )}
                  <div
                    className={`text-[13px] max-w-[70%] break-words ${
                      msg.sender === "self"
                        ? "bg-gray-200 text-black"
                        : "bg-lime-100 text-black"
                    } p-2 px-3 rounded-lg`}
                  >
                    {msg.text}
                    <div className="text-xs text-gray-500 text-right mt-1">
                      {msg.timestamp}
                    </div>
                  </div>
                  {msg.sender === "self" && (
                    <div className="bg-gray-200 w-7 h-7 rounded-full flex-shrink-0">
                      <img
                        src={image}
                        alt="User"
                        className="rounded-full w-7 h-7"
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>

      {/* Input Area */}
      <div className="flex-none flex space-x-2 items-center pt-2 border-t border-gray-200">
        <input
          type="text"
          value={message}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          placeholder="Send message"
          className="flex-1 p-2 pl-4 border text-black bg-gray-200 rounded-full focus:outline-none"
        />
        <button
          className="flex items-center justify-center bg-black rounded-full p-2 h-10 w-10 hover:bg-gray-800 transition-colors"
          // onClick={handleSendMessage}
        >
          <IoSendSharp className="text-lime-500 text-lg" />
        </button>
      </div>
    </div>
  );
}

export default ConversationComponent;

