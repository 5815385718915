/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  useTheme,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  RadioGroup,
  FormControlLabel,
  Radio,
  Badge,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  CircularProgress,
  Chip,
} from "@mui/material";
import Header from "../../../components/header";
import { tokens } from "../../../components/theme";
import { useQuery, useMutation } from "react-query";

import { useNavigate, useParams } from "react-router-dom";
import { fetchNotifications } from "../services/ChatService.js";
import { MessageSquare } from "lucide-react";
import SupportAgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined";
import FiberNewOutlinedIcon from "@mui/icons-material/FiberNewOutlined";
// import DocumentDrawer from "../components/DocumentDrawer.jsx";
// import StaffListingInnerPage from "../components/InnerPages/StaffListingInnerPage.jsx";
import toast, { Toaster } from "react-hot-toast";
// import useWebsocketChat from "../components/hooks/useWebsocketChat.js";
import { assignStaff,getAllStaff,fetchAppliedCourseData } from "../services/AppliedCourseService.js";


const AppliedCourse = () => {
  const { userId } = useParams();
  
  
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [appliedCoursesData, setAppliedCoursesData] = useState([]);
  // const [openDialog, setOpenDialog] = useState(false);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [selectedCourseId, setSelectedCourseId] = useState(null);
  const [notifications, setNotifications] = useState({});
  const [page, setPage] = useState(0);
const [openStaffDrawer, setOpenStaffDrawer] = useState(false);


  const [rowsPerPage, setRowsPerPage] = useState(10);
 const token = localStorage.getItem("authToken");
  // const { newMessages, connectToStudents, markMessagesAsRead } =
  //   useWebsocketChat(
  //     "staff", 
  //     token 
  //   );

  const dateFormatter = (dateString) => {
    const [datePart] = dateString.split(" ");
    const [day, month, year] = datePart.split("/");
    const formattedDate = new Date(`${month}/${day}/${year}`);
    const options = { year: "numeric", month: "long", day: "numeric" };
    return formattedDate.toLocaleDateString(undefined, options);
  };

  const { data: staffList, isLoading: isStaffLoading } = useQuery(
    "staffList",
    getAllStaff
  );

  // const { data: notificationData } = useQuery(
  //   "notifications",
  //   fetchNotifications,
  //   {
  //     onSuccess: (data) => {
  //       const notificationMap = data.reduce((acc, notification) => {
  //         const userId = notification.data.sender_id;
  //         if (userId) {
  //           acc[userId] = (acc[userId] || 0) + 1;
  //         } else {
  //           console.warn("Notification missing user_id:", notification);
  //         }
  //         return acc;
  //       }, {});
  //       setNotifications(notificationMap);
  //     },
  //   }
  // );

  useEffect(() => {
    if (appliedCoursesData.length > 0) {
      const studentIds = appliedCoursesData.map((course) =>
        course.userid.toString()
      );
      // connectToStudents(studentIds);
    }
  }, [appliedCoursesData]);

  const { data, isLoading, isError } = useQuery(
    "appliedCoursesData",
    fetchAppliedCourseData,
    {
      onSuccess: (data) => {
        setAppliedCoursesData(data);
        console.log("course",data);
        
        // const studentIds = data.map(course => course.userid.toString());
        // connectToStudents(studentIds);

      },
      onError: (error) => {
        console.error("Error fetching applied courses:", error);
      },
    }
  );

  const formattedData = appliedCoursesData.map((item) => ({
    ...item,
    date: dateFormatter(item.appliedon),
  }));

//   const assignStaffMutation = useMutation(
//     ({ staffid, leadid }) => assignStaff(Number(staffid), Number(leadid)),
//     {
//       onSuccess: (_, variables) => {
//         setOpenStaffDrawer(false);
//          const assignedStaff = staffList?.find(
//            (staff) => staff.id === Number(variables.staffid)
//         );
//          const selectedCourse = appliedCoursesData.find(
//            (course) => course.id === selectedCourseId
//          );
//         setAppliedCoursesData((prevData) =>
//           prevData.map((course) =>
//             course.userid === selectedCourse.userid
//               ? {
//                   ...course,
//                   status: "allocated",
//                   allocatedStaff: assignedStaff?.name,
//                 }
//               : course
//           )
//         );
//         toast.success(`Successfully assigned to ${assignedStaff?.name}`);
//       },
//       onError: (error) => {
//         console.error("Error assigning staff:", error);
//         toast.error("Error assigning staff. Please try again.");
//       },
//     }
//   );
// const handleAssignStaff = (selectedStaffMember) => {
//   const selectedCourse = appliedCoursesData.find(
//     (course) => course.id === selectedCourseId
//   );
//   assignStaffMutation.mutate({
//     staffid: selectedStaffMember.id,
//     leadid: selectedCourse.userid,
//   });
// };
//   const handleAssignStaff = () => {
//     if (selectedStaff) {
//     console.log("staff id",selectedStaff);
    
//     const selectedCourse = appliedCoursesData.find(
//       (course) => course.id === selectedCourseId
//     );
//     assignStaffMutation.mutate({
//       staffid: Number(selectedStaff),
//       leadid: selectedCourse?.userid,
//     });
//   }
// };

// const handleOpenStaffDialog = (courseId) => {
//   setSelectedCourseId(courseId);
//   const selectedCourse = appliedCoursesData.find(
//     (course) => course.id === courseId
//   );
//   setSelectedStaff(selectedCourse?.allocatedStaff);
//   setOpenStaffDrawer(true);
// };

//   const handleCloseDrawer = () => {
//     setOpenStaffDrawer(false);
//   }
  
  // const resetNotificationCount = (userid) => {
  //   setNotifications((prev) => ({
  //     ...prev,
  //     [userid]: 0,
  //   }));
  // };

  const handleRowClick = (id, userid) => {
    // markMessagesAsRead(userid.toString());
    navigate(
      `/u/${userId}/appliedcourse/planstatus/${id}/${userid}`
    );
  };
  

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
const renderStatusCell = (status, statusname, courseId) => {
  const getStatusStyles = () => {
    switch (status) {
      case "In review":
        return {
          backgroundColor: `${colors.greenAccent[100]}`, 
          borderColor: `${colors.greenAccent[700]}`, 
          textColor: `${colors.greenAccent[700]}`,
          icon: <FiberNewOutlinedIcon sx={{ color: "#FF0000 !important" }} />,
        };
      case "allocated":
        return {
          backgroundColor: "#e0e0e0", 
          borderColor: "#607d8b", 
          textColor: "#607d8b",
          icon: (
            <SupportAgentOutlinedIcon sx={{ color: `#607d8b !important` }} />
          ),
        };
      default:
        return {
          backgroundColor: `${colors.grey[300]}`,
          borderColor: `${colors.grey[600]}`,
          textColor: `${colors.grey[600]}`, 
          icon: <FiberNewOutlinedIcon sx={{ color: "#000 !important" }} />,
        };
    }
  };

  const styles = getStatusStyles();

  return (
    <Chip
      icon={styles.icon}
      label={
        <Typography
          variant="body2"
          color={styles.textColor}
          sx={{ fontWeight: "bold" }}
        >
          {statusname}
        </Typography>
      }
      sx={{
        backgroundColor: styles.backgroundColor,
        borderColor: styles.borderColor,
        color: styles.textColor,
        border: `1px solid ${styles.borderColor}`,
        cursor: "pointer",
        borderRadius: "8px",
        width: "80%",
        justifyContent: "center",
      }}
      onClick={(event) => {
        event.stopPropagation();
        // handleOpenStaffDialog(courseId);
      }}
    />
  );
  };
  
  

  return (
    <Box m="10px 15px">
      <Header
        title="Applied Courses"
        subTitle="View and manage all Applied Courses data here"
      />
      <Toaster position="bottom-right" />
      <Box m="0px 10px" height="78vh">
        <TableContainer
          className="scrollbar-hide"
          component={Paper}
          sx={{
            height: "100%",
            backgroundColor: "#ffffff",
            borderRadius: "12px",
            "& .MuiTableCell-root": {
              borderBottom: "1px solid black",
            },
            "& thead .MuiTableCell-root": {
              color: "#FFFFFF",
            },
            "& tbody .MuiTableCell-root": {
              color: "black",
            },
          }}
        >
          {isLoading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ minWidth: 100, width: "10%" }}>
                      Name
                    </TableCell>
                    <TableCell sx={{ minWidth: 100, width: "10%" }}>
                      Applied Course
                    </TableCell>
                    <TableCell sx={{ minWidth: 100, width: "10%" }}>
                      Phone
                    </TableCell>
                    <TableCell sx={{ minWidth: 100, width: "10%" }}>
                      Date
                    </TableCell>
                    <TableCell sx={{ minWidth: 100, width: "10%" }}>
                      Country
                    </TableCell>
                    <TableCell
                      sx={{ minWidth: 100, width: "10%", textAlign: "center" }}
                    >
                      Status
                    </TableCell>
                    {/* <TableCell sx={{ minWidth: 100, width: "10%" }}>
                      Allocated To
                    </TableCell> */}
                    <TableCell sx={{ minWidth: 100, width: "10%" }}>
                      Plan Allocated
                    </TableCell>
                    <TableCell sx={{ minWidth: 100, width: "10%" }}>
                      Notifications
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {formattedData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <TableRow
                        key={row.id}
                        hover
                        onClick={() => handleRowClick(row.id, row.userid)}
                        sx={{
                          cursor: "pointer",
                        }}
                      >
                        <TableCell>
                          <Typography variant="body2">{row.name}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="body2"
                            sx={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              maxWidth: 150,
                            }}
                          >
                            {row.coursename || "None"}
                          </Typography>
                        </TableCell>
                        <TableCell>{row.mobile}</TableCell>
                        <TableCell>{row.date}</TableCell>
                        <TableCell>
                          <Typography variant="body2">
                            {row.country || "No Country"}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {renderStatusCell(
                            row.status,
                            row.status === "In review" ? "Latest" : row.status,
                            row.id
                          )}
                        </TableCell>

                        {/* <TableCell>
                          <Typography variant="body1">
                            {row.allocatedStaff || "Not Allocated"}
                          </Typography>
                        </TableCell> */}
                        <TableCell>
                          <Typography variant="body1">
                            {row.plan_id === "0" ? "No" : "Yes"}
                          </Typography>
                        </TableCell>
                        <TableCell
                          onClick={() => handleRowClick(row.id, row.userid)}
                        >
                          <Box display="flex" alignItems="center">
                            <Badge
                              badgeContent={
                                // newMessages[row.userid.toString()]
                                // ||
                                0
                              }
                              color={
                                "default"
                                // newMessages[row.userid.toString()] > 0
                                //   ? "error"
                                //   : "default"
                              }
                            >
                              <MessageSquare
                                size={20}
                                color={
                                  colors.grey[500]
                                  // newMessages[
                                  //   row.userid.toString()] > 0
                                  //   ? colors.blueAccent[500]
                                  //   : colors.grey[500]
                                }
                              />
                            </Badge>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              <Box
                sx={{
                  position: "sticky",
                  bottom: 0,
                  zIndex: 1,
                  backgroundColor: "black",
                }}
              >
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={formattedData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  sx={{
                    color: "#ffffff",
                    "& .MuiSelect-icon": {
                      color: "white !important",
                    },
                  }}
                />
              </Box>
            </>
          )}
        </TableContainer>
      </Box>
      {/* <DocumentDrawer
        open={openStaffDrawer}
        onClose={handleCloseDrawer}
        title={
          appliedCoursesData.find((course) => course.id === selectedCourseId)
            ?.status === "allocated"
            ? "Reassign Staff Member"
            : "Select Staff Member"
        }
      >
        <StaffListingInnerPage
          staffs={staffList}
          onStaffSelect={(staff) => {
            handleAssignStaff(staff);
          }}
          onCancel={handleCloseDrawer}
        />
      </DocumentDrawer> */}
    </Box>
  );
};

export default AppliedCourse;
